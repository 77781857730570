import { useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Typography, makeStyles, CircularProgress, ButtonBase } from '@material-ui/core'
import { fetchInstructor } from '../../../../actions/instructorsApi';
import { fetchAllSortedVideos } from '../../../../actions/videoActions';
import { fetchAllCoursesUser } from '../../../../actions/coursesApi';
import banner from '../../../../assets/instructor-banner.jpeg'
import { ClassesTab } from './ClassesTab';
import { CoursesTab } from './CoursesTab';
const publisherId = '616da24ac0eef7f071edd9f4'

const useStyles = makeStyles(() => ({
    page: {
        backgroundColor: 'background.paper',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '10rem',
        '@media (max-width: 960px)': {
            paddingBottom: '5rem',
        }
    },
    header: {
        textTransform: 'uppercase',
        marginTop: '2rem',
        textAlign: 'center',
    },
    subHeader: {
        marginTop: '2rem',
        textAlign: 'center',
        padding: '0 10rem',
        '@media (max-width: 960px)': {
            padding: '0 2rem',
        }
    },
    banner: {
        position: 'relative',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${banner})`,
        backgroundSize: 'cover',
        backgroundPosition: 'flex-start',
        width: '100%',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.2)',
        zIndex: 1,
    },
    button: {
        textTransform: 'uppercase',
        padding: '0.5rem 1.5rem',
        marginTop: '2rem',
    },
    teacherImageContainer: {
        width: 300,
        height: 300,
        overflow: 'hidden',
        borderRadius: '50%',
        marginTop: -150,
        border: '5px solid white',
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    image: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        display: 'block'
    },
}));

const InstructorDetailPage = () => {
    const classes = useStyles()
    const navigate = useNavigate()
    const { instructorId } = useParams()
    const [loading, setLoading] = useState(true)
    const [loadingClasses, setLoadingClasses] = useState(true)
    const [loadingCourses, setLoadingCourses] = useState(true)
    const [instructor, setInstructor] = useState(null)
    const [videos, setVideos] = useState(null)
    const [courses, setCourses] = useState(null)
    const [showClasses, setShowClasses] = useState(true)
    const [showCourses, setShowCourses] = useState(false)

    const getInstructor = useCallback(async () => {
        if (!instructorId) return;
        try {
            const response = await fetchInstructor(instructorId);
            if (response) {
                setInstructor(response);
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }, [instructorId]);

    const getVideos = useCallback(async () => {
        if (!instructor) return;
        setLoadingClasses(true);
        try {
            const response = await fetchAllSortedVideos(publisherId, 1, 1000, {}, { Teacher: instructor.name });
            if (response.videos) {
                setVideos(response.videos);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingClasses(false);
        }
    }, [instructor]);

    const getCourses = useCallback(async () => {
        if (!instructor) return;
        const course_instructor = instructor.id;
        setLoadingCourses(true);
        try {
            const response = await fetchAllCoursesUser(publisherId, course_instructor);
            if (response.allCourses) {
                setCourses(response.allCourses);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingCourses(false);
        }
    }, [instructor]);

    useEffect(() => {
        getInstructor();
    }, [getInstructor]);

    useEffect(() => {
        getVideos();
        getCourses();
    }, [getVideos, getCourses, instructor]);


    return (
        <Box className={classes.page}>
            <Box className={classes.banner}>
                <Box className={classes.overlay} />
            </Box>
            {loading ? <CircularProgress /> :
                instructor &&
                <>
                    <Box className={classes.teacherImageContainer}>
                        <img src={instructor?.picture && instructor.picture.url} alt={instructor?.name} className={classes.image} />
                    </Box>
                    <Typography variant="h4" className={classes.header}>
                        {instructor.name}
                    </Typography>
                    <Typography variant="h6" className={classes.subHeader}>
                        {instructor.bio}
                    </Typography>
                    <Typography variant="h4" className={classes.header}>
                        Learn from {instructor.name}
                    </Typography>
                    <Box sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        mt: 4
                    }}>
                        <ButtonBase onClick={() => {
                            setShowClasses(true);
                            setShowCourses(false);
                        }}>
                            <Typography
                                variant="h6"
                                sx={{
                                    textTransform: 'uppercase',
                                    color: 'primary.main',
                                    textDecoration: showClasses ? 'underline' : 'none',
                                    fontWeight: 700,
                                    mx: 2
                                }}
                            >
                                Classes
                            </Typography>
                        </ButtonBase>
                        <Typography
                            variant="h6"
                            sx={{ mx: 2, color: 'primary.main', fontWeight: 700 }}
                        >
                            |
                        </Typography>
                        <ButtonBase onClick={() => {
                            setShowClasses(false);
                            setShowCourses(true);
                        }}>
                            <Typography
                                variant="h6"
                                sx={{
                                    textTransform: 'uppercase',
                                    color: 'primary.main',
                                    textDecoration: showCourses ? 'underline' : 'none',
                                    fontWeight: 700,
                                    mx: 2
                                }}
                            >
                                Courses
                            </Typography>
                        </ButtonBase>
                    </Box>
                    {showClasses && (loadingClasses ? <CircularProgress /> : <ClassesTab videos={videos} navigate={navigate} />)}
                    {showCourses && (loadingCourses ? <CircularProgress /> : <CoursesTab courses={courses} />)}

                </>
            }
        </Box>
    )
}

export default InstructorDetailPage