import React, { useState, useEffect } from 'react';
import { TextField, Button, LinearProgress, Grid, Typography, ImageListItem, Dialog, Box, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import FileUpload from '../../../components/FileUpload';
import { updateWorkshop, fetchWorkshops } from '../../../actions/workshopActions';
import toast from 'react-hot-toast';
import { createAttachment } from '../../../actions/videoActions';
import ImageCropper from '../../../components/ImageCropper';

function toLocalDateTimeString(isoDateString) {
    const date = new Date(isoDateString);

    // Get the offset in minutes and convert it to milliseconds
    const timezoneOffset = date.getTimezoneOffset() * 60000;

    // Adjust the date to the user's local time
    const localDate = new Date(date.getTime() - timezoneOffset);

    // Convert to the required format YYYY-MM-DDTHH:MM
    return localDate.toISOString().slice(0, 16);
}


export const WorkshopUpdateModal = ({ open, handleClose, workshop, getWorkshops }) => {
    const [workshopData, setWorkshopData] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [newCoverImage, setNewCoverImage] = useState(null)
    const [src, setSrc] = useState(null);
    const [updateAllFuture, setUpdateAllFuture] = useState(false)

    const handleChange = (event) => {
        const { name, value } = event.target;
        setWorkshopData(prev => ({ ...prev, [name]: value }));
    }

    const handleUploadPhoto = async ([attachment]) => {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
            setSrc(fileReader.result);
        };
        fileReader.readAsDataURL(attachment);
    };

    const handleCropComplete = async (croppedImageUrl) => {
        setIsUploading(true);
        try {
            const response = await fetch(croppedImageUrl);
            const blob = await response.blob();
            const croppedFile = new File([blob], 'croppedImage.jpeg', { type: 'image/jpeg' });

            const data = new FormData();
            data.append('files', croppedFile);
            const loading = toast.loading('Uploading cover image...');
            const uploadResponse = await createAttachment(data);
            if (uploadResponse) {
                toast.dismiss(loading);
                toast.success('Cover image uploaded!');
                setWorkshopData((prev) => ({ ...prev, cover: uploadResponse[0] }));
                setNewCoverImage(uploadResponse[0]);
            }
        } catch (error) {
            console.error(error, 'Cover image upload error');
            toast.error('Failed to upload cover.');
        } finally {
            setIsUploading(false);
            setSrc(null); // Reset the source to close the cropper
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        const localDate = new Date(workshopData.date);
        const dateInUTC = localDate.toISOString();
        workshopData.date = dateInUTC;

        const submissionValues = { ...workshopData };

        try {
            const response = await updateWorkshop(workshopData.id, submissionValues, updateAllFuture);
            if (response) {
                toast.success('Event updated successfully!');
                getWorkshops()
                setUpdateAllFuture(false)
                handleClose()
            }
        } catch (error) {
            console.error(error, 'Event update error');
            toast.error('Failed to update event.');
        } finally {
            setIsSubmitting(false);
        }
    }

    useEffect(() => {
        if (workshop) {
            setWorkshopData(workshop)
            setNewCoverImage(workshop.cover)
        }
    }, [workshop])

    if (!workshop || !workshopData) return null

    const formattedDate = toLocalDateTimeString(workshopData.date);

    return (
        <Dialog open={open} onClose={handleClose}>
            <Box sx={{ padding: 3 }}>
                <form onSubmit={handleSubmit}>
                    <Typography variant="h4" sx={{ mt: 2, mb: 2 }}>Update Event</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="title"
                                label="Title"
                                fullWidth
                                value={workshopData.title}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="host"
                                label="Host"
                                fullWidth
                                value={workshopData.host}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="duration"
                                label="Duration (minutes)"
                                type="number"
                                fullWidth
                                value={workshopData.duration}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography>Date & Time</Typography>
                            <input aria-label='Date & Time' type='datetime-local' name='date' value={formattedDate} onChange={handleChange} required />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="information"
                                label="Information"
                                fullWidth
                                value={workshopData.information}
                                onChange={handleChange}
                                multiline
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {src ? (
                                <>
                                    <Typography variant="body2" sx={{ mb: 2 }}>Select desired area to crop your image.</Typography>
                                    <ImageCropper src={src} onCropComplete={handleCropComplete} aspect={1} />
                                </>

                            ) : (
                                <>
                                    <Typography variant="body2" sx={{ mb: 2 }}>Please upload an image with a 1:1 aspect ratio (e.g., 800x800 pixels) to ensure proper display. You can also crop it after uploading.</Typography>
                                    <FileUpload onDrop={handleUploadPhoto} fileType="image" fileLabel="cover" maxFiles={1} accept="image/*" />
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ImageListItem>
                                {newCoverImage && <img src={`${newCoverImage.url}`} atl="cover" />}
                            </ImageListItem>
                        </Grid>
                        {workshopData.recurring && (
                            <Grid item xs={12}>
                                <Typography variant="body1" sx={{ mt: 2, color: 'red' }}>This is a recurring event. Do you want to update all future occurrences?</Typography>
                                <Typography variant="body2" sx={{ mt: 1 }}>Note: Date and time will not be updated if you do not check this box.</Typography>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={updateAllFuture} onChange={(e) => setUpdateAllFuture(e.target.checked)} />} label="Update all future occurrences" />
                                </FormGroup>
                            </Grid>
                        )}

                    </Grid>
                    <br />
                    {isSubmitting && <LinearProgress />}
                    <br />
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting || isUploading}
                        type="submit"
                    >
                        Update Event
                    </Button>
                </form>
            </Box>
        </Dialog>
    )
}
