import { PAYMENTS_URL } from '../constants'
const strapiUrl = process.env.REACT_APP_STRAPI_URL

const headers = () => {
	const accessToken = localStorage.getItem('accessToken')
	const headers = {
		'Content-Type': 'application/json',
		'x-analytics-source': 'web'
	}
	if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`

	return headers
}
/**
 * 
 * @param {'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'} method
 * @param {object | string} body
 * @returns {RequestInit}
 */
const getOpts = (method, body) => {
	return {
		method,
		headers: headers(),
		body: typeof (body) === 'object' ? JSON.stringify(body) : body
	}
}
class InvoiceApi {
	/**
	 * 
	 * @param {Object} params
	 * @param {AbortSignal} abortSignal 
	 * @returns 
	 */
	getInvoices({ limit, starting_after, status, customerId, subscriptionId, mapStrapiUserData, expand } = {}, abortSignal) {
		return new Promise(async (resolve, reject) => {
			try {
				const opts = {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${localStorage.getItem('accessToken')}`
					},
					signal: abortSignal
				}
				const params = new URLSearchParams()
				if (limit) params.append("limit", limit)
				if (starting_after) params.append("starting_after", starting_after)
				if (status) params.append("status", status)
				if (customerId) params.append("customer", customerId)
				if (subscriptionId) params.append("subscription", subscriptionId)
				if (expand) params.append("expand", expand)

				const response = await fetch(`${PAYMENTS_URL}/payments/invoices/list?${params.toString()}`, opts)

				const responseJson = await response.json()

				if (!response || response.status === 400) {
					throw new Error(responseJson.msg)
				}

				if (mapStrapiUserData) {
					const uniqueCustomers = new Set()
					responseJson.data.forEach((invoice) => {
						uniqueCustomers.add(invoice.customer)
					})
					const query = new URLSearchParams()
					for (let customer of uniqueCustomers) {
						query.append("stripecustomerid_in", customer)
					}
					const opts = {
						method: 'GET',
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
						},
					}
					const strapiUserData = await fetch(`${strapiUrl}/users?${query.toString()}`, opts).then((data) => data.json())

					let finalData = responseJson.data.map((invoice) => {
						strapiUserData.some((strapiUser) => {
							if (invoice.customer === strapiUser.stripecustomerid) {
								invoice.strapi_user = strapiUser
								return true
							}
							return false
						})
						return invoice
					})
					responseJson.data = finalData
					resolve(responseJson)
				} else {
					resolve(responseJson)
				}
			} catch (err) {
				if (abortSignal.aborted) return
				console.error('[Payments Api]: ', err)
				resolve({ error: true, msg: err.message })
			}
		})
	}

	/**
	 * 
	 * @param {string} number
	 * @returns stripe invoice https://stripe.com/docs/api/invoices/object?lang=node#invoice_object-id
	 */
	async getInvoice({ invoiceId, expand } = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const accessToken = localStorage.getItem('accessToken')
				const opts = {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`
					}
				}

				const params = new URLSearchParams()
				if (invoiceId) params.append("invoice", invoiceId)
				if (expand) params.append("expand", expand)

				const response = await fetch(`${PAYMENTS_URL}/payments/invoices/bynumber/${invoiceId}`, opts)
				// console.log(response)

				const data = await response.json()


				if (!data || data.status === 400) {
					throw new Error(data.msg)
				}

				return data

			} catch (e) {
				console.error('[Payments Api: Invoice]:', e)
				resolve({ error: true, msg: e.message })
			}

		})

	}

	/**
	 * 
	 * @param {string} id Invoice id
	 * @param {number} amount Invoice amount IN CENTS
	 * @param {'duplicate' | 'fraudulent' | 'requested_by_customer' | 'other'} reason Refund reason
	 */
	async refundInvoice(id, amount, reason) {
		try {
			const opts = getOpts('POST', { id, amount, reason })
			const response = await fetch(`${PAYMENTS_URL}/payments/invoices/refund`, opts)
			await response.json()
		} catch (e) {
			console.error('[Payemtns API: Refund]:', e)
			return { error: true, msg: e.message }
		}
	}

	async getIAPHUBInvoices(iaphubuserid, limit, page) {
		try {
			const opts = getOpts('GET')
			const response = await fetch(`${strapiUrl}/iaphub/gettransactions?iaphubuserid=${iaphubuserid}&limit=${limit}&page=${page}`, opts)
			const data = await response.json()

			return data
		} catch (e) {
			console.error('[Payments API: IAPHUB]:', e)
			return { error: true, msg: e.message }
		}
	}

	async refundAndroidTransaction(orderId, userToUpdateId) {
		try {
			const opts = getOpts('POST', { orderId, userToUpdateId });
			const response = await fetch(`${strapiUrl}/iaphub/refund-android`, opts);
			if (!response.ok) {
				throw new Error(`Error: ${response.status} - ${response.statusText}`);
			}

			const data = await response.json();
			return data;
		} catch (e) {
			console.error('[Payments API: IAPHUB Refund]:', e);
			return { error: true, msg: e.message };
		}
	}
}

export const invoiceApi = new InvoiceApi()